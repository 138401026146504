import './street.css';

function Street() {
  return (
    <div id="content-area">
      <div id="street1">
        <div className="blurred-img" id="road">
          <img className="ground" src="/img/road.png" alt="road in neighbourhood" loading="lazy" />
        </div>
      </div>
      <div id="street2">
        <div className="blurred-img" id="villa">
          <img className="ground" src="/img/villa.png" alt="villas next to road" loading="lazy" />
        </div>
        <p className="light bound top">
          We used to play in our street as kids, running after a tennis ball with our hockey
          sticks held ready. Our goals were made of light metal, sporting flimsy textile nets that
          broke apart. Cars would pass and we had to move our goals to the side.
        </p>
      </div>
      <div id="street3">
        <div className="blurred-img" id="walk">
          <img className="ground" src="/img/walk.png" alt="stairs up to villa" loading="lazy" />
        </div>
        <p className="light bound top">
          The ball would end up in someone&apos;s garden. We stood there in the street, like
          hesitant show jumping horses before a challenging fence. Some neighbours we knew
          very well, some hardly at all.
        </p>
        <p className="light bound">
          Falling to the ground was a painful experience. The asphalt bloodied knees. I used to
          cry a lot when I hurt myself. Why so much sound?
        </p>
        <p className="light bound">
          It sent some kind of signal.
        </p>
      </div>

      <div id="street4">
        <div className="blurred-img" id="garden">
          <img className="ground" src="/img/garden.png" alt="villa garden with parked cars" loading="lazy" />
        </div>
      </div>
      <div id="street5">
        <div className="blurred-img" id="alley">
          <img className="ground" src="/img/alley.png" alt="a walk of trees" loading="lazy" />
        </div>
        <p className="tell dark center" id="street-1">
          In their childhood,
          <br />
          the entangled
          <br />
          web of possible branches
          <br />
          had formed a straight path
          <br />
          <span className="bold yellow">elsewhere.</span>
        </p>
      </div>
    </div>
  );
}

export default Street;
