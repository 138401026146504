import './exilic.css';

function Exilic() {
  return (
    <div id="content-area">
      <div id="exilic1">
        <div className="blurred-img" id="banner">
          <img className="ground" src="/img/banner.png" alt="chimneys" loading="lazy" />
        </div>
        <img className="title" src="/img/logo3.svg" alt="exilic" />
        <p className="tell transparent left middle white" id="exilic-2">
          Later that Wednesday evening,
          <br />
          many began their displacement.
          <br />
          <br />
          As a consequence, they entered
          {' '}
          <br />
          a state of self-exile,
          <br />
          for some, lasting years.
        </p>
      </div>
      <div id="exilic2">
        <img className="ground" src="/img/Asset1.svg" alt="facade" />
        <p className="tell dark center" id="exilic-1">
          Unable to return home,
          <br />
          they found themselves wandering,
          <br />
          <span
            className="middle bold yellow"
          >
            lost in a maze
          </span>
        </p>
      </div>
      <div id="exilic3">
        <div className="blurred-img" id="path">
          <img className="ground" src="/img/path.png" alt="facade" loading="lazy" />
        </div>
        <div className="insert" id="insert-1">
          <div className="inner-insert">
            <div className="blurred-img" id="clock">
              <img className="ground" src="/img/clock.png" alt="facade" loading="lazy" />
            </div>
          </div>

        </div>
        <p className="tell dark center" id="exilic-4">
          Though a
          <br />
          <span className="bold yellow">terror of urgency</span>
          <br />
          prevailed, time
          <br />
          had stopped running.
          <br />
          <br />
          The same moment repeating,
          <br />
          like a stream of
          <br />
          proposals from a
          <br />
          carnival planning committee
          <br />
          <span className="bold yellow">
            devoid of any imagination
            <br />
            pertaining to the real
          </span>
        </p>
      </div>

      <div id="exilic1b">
        <div className="blurred-img" id="horizon">
          <img className="ground" src="/img/horizon.png" alt="chimneys" loading="lazy" />
        </div>
        <p className="tell dark center" id="exilic-3">
          <span className="middle bold yellow">Recollections of home</span>
          <br />
          became a source of nostalgia.
        </p>
      </div>
    </div>
  );
}

export default Exilic;
