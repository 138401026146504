import Garden from './Garden';

const generosityLinks = [
  {
    path: 'generosity/page-1',
    element: <Garden />,
  },
];

export default generosityLinks;
