import CornerBackground from './cornerBackground';

// eslint-disable-next-line react/prop-types
function Footer({ children }) {
  return (
    <footer className="footer">
      <CornerBackground />
      {children}
    </footer>
  );
}

export default Footer;
