import './layout.css';
import Footer from './Footer';
import Exilic from '../booklets/exilic/Exilic';
import Sinking from '../booklets/exilic/Sinking';
import Striving from '../booklets/exilic/Striving';
import Street from '../booklets/exilic/Street';
import Garden from '../booklets/generosity/Garden';
import Erasure from '../booklets/exilic/Erasure';
import Classic from './Classic';
import CornerBackground from './cornerBackground';

// eslint-disable-next-line react/prop-types
function LayoutVersion() {
  return (
    <div id="page-1">
      <div id="header">
        <CornerBackground />
        <div id="site-brand">
          <h2>Exilic</h2>
          <h1>A Place to Love</h1>
        </div>
      </div>
      <Exilic />
      <Footer>
        <h3>A polite amnesia crept closer</h3>
      </Footer>
      <Classic />
      <Footer>
        <h3>Placed below the surface</h3>
      </Footer>
      <Sinking />
      <Footer>
        <h3>Finding a cultivated delight</h3>
      </Footer>
      <Garden />
      <Footer>
        <h3>Remembering the place of one childhood</h3>
      </Footer>
      <Street />
      <Footer>
        <h3>Living in the factory of improvement</h3>
      </Footer>
      <Striving />
      <Footer>
        <h3>In this reality, presence felt so arbitrary</h3>
      </Footer>
      <Erasure />
      <Footer>
        <div id="footer">
          <p>by Tobias Engberg</p>
          <br />
          <a href="mailto:info@exilic">Send me an email</a>
          <br />
          <a href="https://www.linkedin.com/in/tobiasengberg/" target="blank">Visit me on LinkedIn</a>
          <br />
          <br />
          <p>
            Copyright &#169;
            {' '}
            {new Date().getFullYear()}
            {' '}
            Tobias Engberg
          </p>
        </div>
      </Footer>
    </div>
  );
}

export default LayoutVersion;
