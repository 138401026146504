import React from 'react';
import { ReactComponent as Corner } from './corner.svg';
import './corner.css';

function CornerBackground() {
  return (
    <>
      <Corner />
      <Corner />
      <Corner />
      <Corner />
    </>
  );
}

export default CornerBackground;
