import './classic.css';

function Classic() {
  return (
    <div id="content-area">
      <div id="classic1">
        <div className="blurred-img" id="park">
          <img className="ground" src="/img/park.png" alt="opera house in park" />
        </div>
        <img id="gradient-classic" className="ground" src="/img/rubber.png" alt="hand with pencil" />
        <p className="tell transparent left middle white" id="classic-2">
          Since long,
          <br />
          places had been
          <br />
          found wanting, like
          <br />
          preparatory sketches.
          <br />
          Imperfect.
          <br />
          Tentative. 
          <br /> 
          Disappointing.
          <br />
          <br />
          Places had thus been
          <br /> 
          submitted to an
          <br />
          all-embracing
          <br />
          state of erasure,
          <br />
          making room
          <br />
          for perfection.
          <br />
          <br />
          This disappearance
          {' '}
          <br />
          made the displacement
          <br />
          even more disorienting.
        </p>
      </div>
    </div>
  );
}

export default Classic;
