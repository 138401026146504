import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import './index.css';
import './reset.css';
import Error from './pages/Error';
import Index from './pages/Index';
import exilicLinks from './booklets/exilic/exilicLinks';
import generosityLinks from './booklets/generosity/generosityLinks';
import LayoutVersion from './pages/LayoutVersion';

const pathBase = [
  {
    path: '/',
    element: <Index />,
  }];

const paths = pathBase.concat(exilicLinks, generosityLinks);

const router = createBrowserRouter([
  {
    path: '/',
    element: <LayoutVersion />,
    errorElement: <Error />,
    children: paths,
  },

]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
