import './garden.css';

function Garden() {
  return (
    <div id="content-area">
      <div id="garden1b">
        <div className="blurred-img" id="ladybird">
          <img className="ground" src="/img/ladybird.png" alt="chimneys" loading="lazy" />
        </div>
        <p className="tell dark center" id="garden-1">
          The tapestry of flowers
          <br />
          in spring,
          <br />
          brought hope of
          <br />
          <span className="middle bold yellow">
            a cyclical change
          </span>
          <br />
          of great meaning
        </p>
      </div>
      <div id="garden1">
        <div className="blurred-img" id="roses">
          <img className="ground" src="/img/roses.png" alt="roses" loading="lazy" />
        </div>
      </div>
      <div id="garden2">
        <div id="garden2a">
          <div className="blurred-img" id="peony2">
            <img className="ground" src="/img/peony2.png" alt="peony" loading="lazy" />
          </div>
        </div>
        <div id="garden2b">
          <p className="light bound top bottom">
            In blossoming gardens, they had watched in awe, as good willing
            nature exhibited remarkable
            <span className="bold italic"> generosity</span>
            {' '}
            each year – colours bright and cheerful.
          </p>
          <div>
            <div className="blurred-img" id="rose">
              <img className="ground" src="/img/rose.png" alt="rose" loading="lazy" />
            </div>
          </div>
        </div>
      </div>
      <div id="garden3">
        <p className="light bound bottom">
          Those alarming signs provided a sense of calm
          <span className="bold italic">
            {' '}
            excitement
          </span>
          ...
          no nervous anticipation of the calamity if pushed,
          but a happy appreciation of what was and what would come back next season.
        </p>
        <div className="blurred-img" id="peony">
          <img className="ground" src="/img/peony.png" alt="facade" loading="lazy" />
        </div>
      </div>

      <div id="garden4">
        <div className="blurred-img" id="violets">
          <img className="ground" src="/img/violets.png" alt="app interface" loading="lazy" />
        </div>
      </div>

    </div>
  );
}

export default Garden;
