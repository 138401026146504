import './sinking.css';

function Sinking() {
  return (
    <div id="content-area">
      <div id="sinking1">
        <div className="blurred-img" id="vibrance">
          <img className="ground" src="/img/vibrance.png" alt="road in neighbourhood" loading="lazy" />
        </div>
        <p className="tell dark center" id="sinking-1">
          Paying attention in this
          <br />
          <span className="middle bold yellow">disoriented state </span>
          <br />
          felt to them
          <br />
          as if experiencing
          <br />
          a surrounding environment
          <br />
          flooded with water.
          <br />
          <br />
          Their weightless bodies
          <br />
          suspended in a
          <br />
          constant
          <br />
          <span className="middle bold yellow"> search of meaning.</span>
        </p>
      </div>

    </div>
  );
}

export default Sinking;
