import './erasure.css';

function Erasure() {
  return (
    <div id="content-area">
      <div id="erasure3">
        <div className="blurred-img" id="cricket">
          <img className="ground" src="/img/cricket.png" alt="facade" loading="lazy" />
        </div>
      </div>
      <div id="erasure4">
        <div className="blurred-img" id="cricketfaint">
          <img className="ground" src="/img/cricketfaint.png" alt="facade" loading="lazy" />
        </div>
      </div>
      <div id="erasure5">
        <div className="blurred-img" id="cricketerased">
          <img className="ground" src="/img/cricketerased.png" alt="facade" loading="lazy" />
        </div>
      </div>
    </div>
  );
}

export default Erasure;
