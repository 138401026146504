import React from 'react';
import Exilic from './Exilic';
import Street from './Street';
import Striving from './Striving';
import Erasure from './Erasure';
import Sinking from './Sinking';

const exilicLinks = [
  {
    path: 'exilic/page-1',
    element: <Exilic />,
  },
  {
    path: 'exilic/page-2',
    element: <Street />,
  },
  {
    path: 'exilic/page-3',
    element: <Striving />,
  },
  {
    path: 'exilic/page-4',
    element: <Erasure />,
  },
  {
    path: 'exilic/page-5',
    element: <Sinking />,
  },

];

export default exilicLinks;
