import './index.css';
import { useOutletContext } from 'react-router-dom';

// eslint-disable-next-line react/prop-types
function Index() {
  const [selectBooklet] = useOutletContext();

  return (
    <div id="index1">
      <p>Main</p>
      <button type="submit" onClick={selectBooklet} value="0">Exilic</button>
      <button type="submit" onClick={selectBooklet} value="1">Generosity</button>
    </div>
  );
}

export default Index;
