import './striving.css';

function Striving() {
  return (
    <div id="content-area">
      <div id="striving4a">
        <div className="blurred-img" id="factory">
          <img src="/img/factory.png" alt="app interface" loading="lazy" />
        </div>
        <div className="blurred-img" id="phone">
          <img src="/img/phone.png" alt="app interface" loading="lazy" />
        </div>
        <p className="tell light-blue center transparent" id="striving-1">
          They had gotten used to strive upwards, by leaving the darkness
          behind.
          <br />
          Enlightenment had promised a sense of liberty.
        </p>
        <p className="tell light-blue center transparent" id="striving-4">
          But something had changed.
          <br />
          <br />
          Like the case of
          <br />
          an ambitious circus dog
          <br />
          <span className="bold">paying the utmost attention.</span>
          <br />
          <br />
          Constantly frenetic,
          <br />
          but never being quick enough.
          <br />
          Always one moment behind.
          <br />
          <br />
          Never enough time for the
          <br />
          curious dog mind to form into
          <br />
          a sharp thought the fleeting
          <br />
          suspicion that the show makes
          <br />
          no sense. That it is all idiotic.
        </p>
        <p className="tell transparent left middle white" id="striving-2">
          <span className="bold yellow">
            With reality mediated through haptic feedback,
            <br />
            life had become a nervous system of signs.
          </span>
        </p>
      </div>
    </div>
  );
}

export default Striving;
